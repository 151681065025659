import React from "react";
import styled from "@emotion/styled";
import { Carousel } from "antd";

const StyledComponent = styled.div`
  .image-slide {
    height: ${props => props.height}px;
    position: relative; /* Add this to position the background image */
  }
  .slide-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .slide-content {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 2em;
    background: rgba(0,0,0,0.2);
    .ant-typography {
      line-height: 1.5em;
      font-weight: 300;
      background: rgba(0,0,0,0.4);
      padding: 10px;
    }
  }
`;

const Slider = ({ autoplay = true, height, fade=true, dots=true, arrows=false, children }) => (
  <StyledComponent height={height}>
    <Carousel autoplay={autoplay} fade={fade} dots={dots} arrows={arrows}>
      {children}
    </Carousel>
  </StyledComponent>
);

export default Slider;
