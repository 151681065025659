import {
  Typography,
  Image,
  Flex,
  Button,
  Col,
  Row,
  Card,
  Avatar,
  Space,
} from "antd";
import styled from "@emotion/styled";
import Slider from "./Slider";
import CardComponent from "./CardComponent";
import {
  ArrowRightOutlined,
  VideoCameraAddOutlined,
  FilePdfOutlined,
  FieldTimeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  AimOutlined,
  FireOutlined,
  FormOutlined,
} from "@ant-design/icons";
import DividerComponent from "./DividerComponent";
import { Link } from "react-router-dom";

const StyledComponent = styled.div`
  .ant-carousel {
    margin-bottom: 4em;
  }
  .about-me {
    img {
      border-radius: 50%;
    }
    .text-content {
      line-height: 15px;
      margin-left: auto;
      .ant-typography {
        text-align: justify;
      }
    }
  }
  .story-card {
    background: rgba(20, 137, 230, 0.6);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;

    .ant-card-body {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .speciality {
    min-height: 200px;
    .ant-card {
      height: 100%;
    }
    .anticon {
      font-size: 2.8em;
    }
    .ant-typography {
      margin-left: 2em;
      text-align: justify;
    }
  }
  .course-card {
    .ant-card {
      background: transparent;
    }
  }
`;

const { Title, Text } = Typography;

const slides = [
  {
    id: 1,
    caption: `"We trust, Python is not just a programming language, but a philosophy of simplicity, readability, and limitless possibilities."`,
    image: "slide-img-1.jpg",
  },
  {
    id: 2,
    caption: `"With Python, the only limit is your imagination - so dream big, code fearlessly, and let your creativity soar."`,
    image: "frontend-technology.jpg",
  },
  {
    id: 3,
    caption: `"In a world of complexity, Python is your compass - guiding you through the maze of challenges with its simplicity and clarity."`,
    image: "data-science.jpg",
  },
  {
    id: 4,
    caption: `"With Python, you're not just writing code, you're crafting solutions, building bridges between ideas and actions, and shaping the future of technology."`,
    image: "complete-python.jpg",
  },
];

const stories = [
  {
    slides: [
      {
        id: 1,
        avatar: "https://xsgames.co/randomusers/avatar.php?g=male",
        story:
          "This course is amazing! I learned so much and highly recommend it to anyone.",
        name: "John Doe",
        location: "LA, 19098",
      },
      {
        id: 2,
        avatar: "https://xsgames.co/randomusers/avatar.php?g=female",
        story:
          "I never thought learning could be this fun. Thank you for such an engaging course!",
        name: "Frank Ian",
        location: "NW, 19098",
      },
      {
        id: 3,
        avatar: "https://api.multiavatar.com/zoe.svg",
        story:
          "This course is amazing! I learned so much and highly recommend it to anyone.",
        name: "John Doe",
        location: "LA, 19098",
      },
    ],
  },
  {
    slides: [
      {
        id: 1,
        avatar: "https://xsgames.co/randomusers/avatar.php?g=male",
        story:
          "This course is amazing! I learned so much and highly recommend it to anyone.",
        name: "John Doe",
        location: "LA, 19098",
      },
      {
        id: 2,
        avatar: "https://xsgames.co/randomusers/avatar.php?g=female",
        story:
          "I never thought learning could be this fun. Thank you for such an engaging course!",
        name: "Frank Ian",
        location: "NW, 19098",
      },
      {
        id: 3,
        avatar: "https://api.multiavatar.com/kathrin.svg",
        story:
          "I never thought learning could be this fun. Thank you for such an engaging course!",
        name: "Frank Ian",
        location: "NW, 19098",
      },
    ],
  },
  {
    slides: [
      {
        id: 1,
        avatar: "https://xsgames.co/randomusers/avatar.php?g=male",
        story:
          "This course is amazing! I learned so much and highly recommend it to anyone.",
        name: "John Doe",
        location: "LA, 19098",
      },
      {
        id: 2,
        avatar: "https://xsgames.co/randomusers/avatar.php?g=female",
        story:
          "I never thought learning could be this fun. Thank you for such an engaging course!",
        name: "Frank Ian",
        location: "NW, 19098",
      },
      {
        id: 3,
        avatar: "https://api.multiavatar.com/zoe.svg",
        story:
          "This course is amazing! I learned so much and highly recommend it to anyone.",
        name: "John Doe",
        location: "LA, 19098",
      },
    ],
  },
];

const specialities = [
  {
    icon: <VideoCameraAddOutlined />,
    title: "Session Recording",
    content:
      "Every live class will be recorded and shared to student immediate after the class. ",
  },
  {
    icon: <FilePdfOutlined />,
    title: "Course Material",
    content:
      "Entire course material will be shared to student during starting of the course itself.",
  },
  {
    icon: <FieldTimeOutlined />,
    title: "Assignments",
    content:
      "Lot of programs are shared to students for practicing code in the form of assignments during Day-1 of training.",
  },
  {
    icon: <UserOutlined />,
    title: "Accessible Trainer",
    content:
      "Being an accessible trainer, Mr. D. Redacted has been ensuring that all students have equal opportunities to learn and succeed, regardless of their backgrounds, abilities, or circumstances.",
  },
  {
    icon: <QuestionCircleOutlined />,
    title: "Question Hour",
    content:
      "Dedicated 15 minutes question hour is also scheduled on every Saturday's class to clarify student's doubts.",
  },
  {
    icon: <FormOutlined />,
    title: "Weekly Tests",
    content:
      "Conducts a test on every week, review the code written by every student to provide feedback to them.",
  },
  {
    icon: <AimOutlined />,
    title: "Highlight IMP",
    content:
      "Every important interview questions will be high lighted during concept explanation in class.",
  },
  {
    icon: <FireOutlined />,
    title: "100% Job Assistance",
    content:
      "Trainer and his supporting team will provide complete job assistance to every student, but can't provide job guarantee.",
  },
];

const courses = [
  {
    img: "../complete-python.jpg",
    name: "Complete Python",
    instructor: "Redacted",
    designation: "Redacted College, 20+ yrs exp",
    rating: 4.6,
    reviews: 78900,
  },
  {
    img: "../python-fullstack.jpg",
    name: "Python Fullstack",
    instructor: "Redacted",
    designation: "Redacted College, 20+ yrs exp",
    rating: 4.2,
    reviews: 4532,
  },
  {
    img: "../frontend-technology.jpg",
    name: "Frontend Technologies",
    instructor: "Redacted",
    designation: "Redacted College, 20+ yrs exp",
    rating: 4.9,
    reviews: 989,
  },
  {
    img: "../data-science.jpg",
    name: "Python Fullstack with Data Science",
    instructor: "Redacted",
    designation: "Redacted College, 20+ yrs exp",
    rating: 4.4,
    reviews: 34532,
  },
];

const StyledSliderComponent = styled.div`
  .slideshow-image-0 {
    background: url("../slide1.jpg");
    background-size: cover;
    background-position: left top;
  }
  .slideshow-image-1 {
    background: url("../slide-img-2.jpg");
    background-size: cover;
    background-position: left top;
  }
  .slideshow-image-2 {
    background: url("../slide3.jpg");
    background-size: cover;
    background-position: left top;
  }
  .slideshow-image-3 {
    background: url("../slide4.jpg");
    background-size: cover;
    background-position: top left;
  }
`;

const StyledCompactComponent = styled.div`
  flex: 1;
  width: 80%;
  margin: 0 auto;
  overflow-x: hidden;
`;
const Home = () => {
  return (
    <StyledComponent>
      {/* Carousel */}
      <div style={{ marginTop: "1em" }}>
        <Slider height={450}>
          {slides.map((slide, index) => (
            <div className='slick-slide image-slide' key={slide.id}>
              <StyledSliderComponent>
                <div
                  className={`slide-image slideshow-image-${index}`}
                  url={slide.image}
                ></div>
                <div className='slide-content'>
                  <Title
                    level={2}
                    style={{ color: "#fff", textAlign: "center" }}
                  >
                    {slide.caption}
                  </Title>
                </div>
              </StyledSliderComponent>
            </div>
          ))}
        </Slider>
      </div>
      <DividerComponent />
      <StyledCompactComponent>
        {/* About Me */}
        <Flex align='start' className='about-me' justify={"space-between"}>
          <Image
            src='instructor.jpg'
            preview={false}
            style={{ maxWidth: "350px" }}
          />
          <Flex vertical style={{ width: "60%" }}>
            <Title level={1}>About Me</Title>
            <Space direction={"vertical"} className='text-content'>
              <Text>
                D. Redacted is a seasoned professional with a robust
                educational background, holding an M.Tech degree from IIT
                Roorkee. With over 20 years of experience in the IT industry,
                Redacted has amassed extensive knowledge and skills in Python,
                Data Science technologies.
              </Text>
              <Text>
                Notably, he trained 60+ batches so far successfully and possess
                over a decade of experience in training, indicating a strong
                capability for imparting knowledge and training graduates, job
                aspirants. Having worked with multiple MNC companies across
                different continents, Mr. Redacted likely has a diverse range
                of experiences and perspectives in the IT sector.
              </Text>
              <Text>
                This global exposure suggests adaptability, cultural awareness,
                and potentially a breadth of expertise in various IT
                technologies. Overall, D. Redacted appears to be a seasoned
                professional with a wealth of experience and expertise to offer
                in the IT industry.
              </Text>
            </Space>
            <Link to={"/about?href=D.Redacted"}>
              <Button
                type='primary'
                size='large'
                style={{ width: "200px", marginTop: "10px" }}
              >
                More Details <ArrowRightOutlined />
              </Button>
            </Link>
          </Flex>
        </Flex>
        <DividerComponent />
        {/* Courses Offered */}
        <div>
          <Row gutter={16}>
            <Col className='gutter-row' span={24}>
              <Flex align='center' justify='center' vertical>
                <Title level={2}>Courses Offering</Title>
                <Text>
                  Here are some IT courses that Mr. D. Redacted has been
                  offering based on their qualifications and experience:
                </Text>
              </Flex>
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ marginTop: "4em" }}>
            {courses.map((item, index) => (
              <Col span={6} key={index} className='course-card'>
                <CardComponent item={item} />
              </Col>
            ))}
          </Row>
        </div>
        <DividerComponent />
        {/* Courses Specialities */}
        <div>
          <Row gutter={16}>
            <Col className='gutter-row' span={24}>
              <Flex align='center' justify='center' vertical>
                <Title level={2}>Courses Specialities</Title>
                <Text>
                  Here are some key features that Mr. D. Redacted has been
                  incorporating into their courses to enhance the learning
                  experience:
                </Text>
              </Flex>
            </Col>
          </Row>
          <Row gutter={[16, 24]} style={{ marginTop: "4em" }}>
            {specialities.map((item, index) => (
              <Col span={6} className='speciality' key={index}>
                <Card>
                  <Flex vertical>
                    <span style={{ margin: "0 auto" }}>{item.icon}</span>
                    <Title level={5}>{item.title}</Title>
                    <Text>{item.content}</Text>
                  </Flex>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <DividerComponent />
        {/* Student Stories */}
        <div>
          <Row gutter={16}>
            <Col className='gutter-row' span={24}>
              <Flex align='center' justify='center' vertical>
                <Title level={2}>Student Stories</Title>
                <Text>
                  Here are some stories students shared about learning
                  experience, course content, teaching skills of trainer, etc...
                  during the training:
                </Text>
              </Flex>
            </Col>
          </Row>
          <div style={{ marginTop: "4em" }}>
            <Slider height={250} autoplay={false} arrows={true} fade={false}>
              {stories.map((item, index) => (
                <div className='slick-slide image-slide' key={index}>
                  <Row gutter={16} wrap={false}>
                    {item.slides?.map((slide, slideIndex) => (
                      <Col span={8}>
                        <Card
                          key={slideIndex}
                          className='story-card'
                          flex={true}
                        >
                          <Flex vertical={true}>
                            <Text style={{ lineHeight: "30px" }}>
                              {slide.story}
                            </Text>
                            <Flex align="center"
                              style={{marginTop: '10px'}}
                            >
                              <Avatar
                                size={64}
                                src={slide.avatar}
                              />
                              <Flex vertical style={{marginLeft: '10px'}}>
                                <Title level={5} style={{margin: 0}}>{slide.name}</Title>
                                <Text>{slide.location}</Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </StyledCompactComponent>
    </StyledComponent>
  );
};

export default Home;
