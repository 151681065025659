import React from "react";
import {
  Col,
  Divider,
  Row,
  Image,
  Typography,
  Flex,
  Card,
  List,
  Rate,
  Badge,
  Timeline,
} from "antd";
import styled from "@emotion/styled";
import DividerComponent from "./DividerComponent";
import { WhatsAppOutlined, YoutubeOutlined, InstagramOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const StyledComponent = styled.div`
  .banner {
    .ant-col {
        height: 400px;
        display: flex;
        background: linear-gradient(45deg, #007bff, #28a745);
        & > div {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-typography {
            color: #fff;
            text-align: center;
            padding: 10px;
        }
        &.queries {
            width: 32.66%;
        }
        &.partnership {
            width: 32.66%
        }
        &.registrations {
            width: 32.66%
        }
        &.separator {
            background: #fff;
            width: 1%;
            transform: rotate(24deg);
            height: 430px;
            margin-top: -28px;
        }
        }
    }
  }
  .gutter-row {
    background: #fff;
    border: 1px solid #f0f0f0;
    padding: 20px;
    border-radius: 4px;
  }
    .image-cards {
        // width: 90%;
        margin-top: -2em !important;
        .ant-col {
            margin-bottom: 1em;
        }
        .ant-card {
            background: #fff;
            box-shadow: 1px 1px 6px rgba(0,0,0,0.1);
            display: flex;
            align-items: center;
            // justify-content: center;
            min-height: 200px;

            .information {
                margin-left: 2em;
                margin-right: auto;
                width: 80%;
            }
            h1 {
                margin: 0;
                // color: #1489e6
            }
            svg 
            {
                border: 1px solid #ff9629;
                border-radius: 50%;
                padding: 16px;
                width: 68px;
                height: 68px;
                background: #ff9629;
                color: #fff;
            }
        }
    }
  @media screen and (max-width: 576px) {
    .ant-col {
      margin-bottom: 8px;
    }
  }

  @media screen and (min-width: 577px) {
    .column-1 {
      flex: 1;
      .gutter-row {
        height: 100%;
      }
    }
`;

const Contact = () => (
  <StyledComponent>
    <Row gutter={0} className='banner'>
      <Col xs={24} md={24} lg={24} flex>
        <div className='queries'>
          <Title level='2'>Additional <br/> Queries ?</Title>
        </div>
        <div className='separator'></div>
        <div className='partnership'>
          <Title level='2'>Training <br/> Partnership ?</Title>
        </div>
        <div className='separator'></div>
        <div className='registrations'>
          <Title level='2'>Last Minute Registrations?</Title>
        </div>
      </Col>
    </Row>
    <Row gutter={16} className='image-cards'>
      <Col xs={24} md={6} lg={12}>
        <Card>
          <Flex justify='space-between' align='center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='w-6 h-6'
            >
              <path
                fillRule='evenodd'
                d='M19.5 9.75a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.69l4.72-4.72a.75.75 0 1 1 1.06 1.06L16.06 9h2.69a.75.75 0 0 1 .75.75Z'
                clipRule='evenodd'
              />
              <path
                fillRule='evenodd'
                d='M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z'
                clipRule='evenodd'
              />
            </svg>
            <Flex vertical style={{ marginRight: "auto", marginLeft: "2em" }}>
              <Text>Call us on </Text>
              <Title level={4} type='secondary'>
                +91 Removed for privacy
              </Title>
              <Text> (9 am to 9 pm IST)</Text>
            </Flex>
          </Flex>
        </Card>
      </Col>
      <Col xs={24} md={6} lg={12}>
        <Card>
          <Flex justify='space-between' align='center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              className='w-6 h-6'
            >
              <path d='M19.5 22.5a3 3 0 0 0 3-3v-8.174l-6.879 4.022 3.485 1.876a.75.75 0 1 1-.712 1.321l-5.683-3.06a1.5 1.5 0 0 0-1.422 0l-5.683 3.06a.75.75 0 0 1-.712-1.32l3.485-1.877L1.5 11.326V19.5a3 3 0 0 0 3 3h15Z' />
              <path d='M1.5 9.589v-.745a3 3 0 0 1 1.578-2.642l7.5-4.038a3 3 0 0 1 2.844 0l7.5 4.038A3 3 0 0 1 22.5 8.844v.745l-8.426 4.926-.652-.351a3 3 0 0 0-2.844 0l-.652.351L1.5 9.589Z' />
            </svg>

            <Flex vertical className={'information'}>
              <Text>Email us to </Text>
              <Title level={4} type='secondary'>
                Removed for privacy
              </Title>
            </Flex>
          </Flex>
        </Card>
      </Col>
      <Col xs={24} md={6} lg={12}>
        <Card>
          <Flex justify='space-between' align='center'>
            <Image src="../whatsapp.png" preview={false} width={80}/>
            <Flex vertical className={'information'}>
              <Text>Chat us on Whatsapp </Text>
              <Title level={4} type='secondary'>
                +91Removed for privacy
              </Title>
              <Text> (24/7)</Text>
            </Flex>
          </Flex>
        </Card>
      </Col>
      <Col xs={24} md={6} lg={12}>
        <Card>
          <Flex justify='space-between' align='center'>
            <Image src="../youtube.png" preview={false} width={80}/>
            <Flex vertical className={'information'}>
              <Text>Follow us on Youtube Channel </Text>
              <Title level={4} type='secondary'>
                @Removed for privacy
              </Title>
            </Flex>
          </Flex>
        </Card>
      </Col>
      <Col xs={24} md={6} lg={12}>
        <Card>
          <Flex justify='space-between' align='center'>
            <Image src="../instagram.png" preview={false} width={80}/>
            <Flex vertical style={{ marginRight: "auto", marginLeft: "2em" }}>
              <Text>Follow us on Instagram</Text>
              <Title level={4} type='secondary'>
                Removed for privacy
              </Title>
            </Flex>
          </Flex>
        </Card>
      </Col>
      <Col xs={24} md={6} lg={12}>
        <Card>
          <Flex justify='space-between' align='center'>
            <Image src="../linkedin.png" preview={false} width={80}/>
            <Flex vertical style={{ marginRight: "auto", marginLeft: "2em" }}>
              <Text>Follow us on Linkedin</Text>
              <Title level={4} type='secondary'>
                Removed for privacy
              </Title>
            </Flex>
          </Flex>
        </Card>
      </Col>
    </Row>
  </StyledComponent>
);

export default Contact;
