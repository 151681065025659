import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Flex,
  Image,
  Typography,
  Badge,
  Button,
  Rate,
  Space,
  Tag,
} from "antd";
import DividerComponent from "./DividerComponent";
import styled from "@emotion/styled";

const { Paragraph, Text, Title } = Typography;

const StyledComponent = styled.div`
  margin-top: 1em;
  .avatar {
    width: 220px;
    .ant-image {
      position: relative;
      .ant-image-img {
        width: 220px;
        height: 220px;
        border-radius: 50%;
        margin-left: 10px;
        position: absolute;
        z-index:1;
      }
  
      &::before {
        content: " ";
        display: block;
        width: 220px;
        height: 220px;
        position: absolute;
        border-radius: 50%;
        border: 10px solid #1489e6;
      }
    }
  }
  
`;

const StyledReview = styled.div`
  flex: 1;
  margin-left: 5%;
  .ant-card {
    // background: ${(props) => props.color};
    background: transparent;
    min-height: 200px;
    border-left: none;

    .quote-image {
      position: absolute;
      top: -63px;
      left: -30px;
    }
  }
`;

const stories = [
  {
    name: "John Doe",
    state: "California",
    country: "USA",
    batchNumber: 6,
    course_name: "Python Fullstack",
    rating: 4.3,
    avatar: "../instructor.jpg",
    bgColor: "rgb(194, 240, 200)",
    commentType: "text",
    comment:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vero omnis soluta corporis impedit ipsa libero eum placeat, quam tenetur in laborum cum numquam temporibus voluptatibus eveniet harum repellat accusamus?",
  },
  {
    name: "John Doe",
    state: "Texas",
    country: "USA",
    batchNumber: 6,
    course_name: "Python Fullstack",
    rating: 4.0,
    avatar: "../instructor.jpg",
    bgColor: "rgb(153, 230, 163)",
    commentType: "video",
    comment: "https://www.youtube.com/embed/AIma4XLjA08?si=NMAf9P_nMc-YlG8E",
  },
  {
    name: "John Doe",
    state: "Florida",
    country: "USA",
    batchNumber: 6,
    course_name: "Python Fullstack",
    rating: 4,
    avatar: "../instructor.jpg",
    bgColor: "rgb(153, 230, 163)",
    commentType: "image",
    comment: "../slide1.jpg",
  },
];

const TextReview = ({ comment }) => {
  return (
    <Paragraph>
      {/* <blockquote> */}
        {/* <Image src='../quote.png' preview={false} width={20} /> */}
        {comment}
        {/* <Image src='../double-quotes.png' preview={false} width={20} /> */}
      {/* </blockquote> */}
    </Paragraph>
  );
};

const ImageReview = ({ comment }) => {
  return <Image src={comment} preview={false} height={400} />;
};

const VideoReview = ({ comment }) => {
  return (
    <iframe
      height='400'
      src={comment}
      style={{ border: 0, borderRadius: "8px" }}
      frameborder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      referrerpolicy='strict-origin-when-cross-origin'
      allowfullscreen
    ></iframe>
  );
};

const StoryComponent = () => {
  return (
    <StyledComponent>
      {stories?.map((story) => (
        <Flex vertical>
          <Flex>
            <div className="avatar">
              <span className="border-left"></span>
              <Image src={story.avatar} preview={false}/>
            </div>
            <StyledReview color={story.bgColor}>
              <Card bordered>
                <Image src='../quote.png' className={'quote-image'} preview={false} width={50}/>
                <Flex vertical>
                  {story.commentType === "text" && (
                    <TextReview comment={story.comment} />
                  )}
                  {story.commentType === "video" && (
                    <VideoReview comment={story.comment} />
                  )}
                  {story.commentType === "image" && (
                    <ImageReview comment={story.comment} />
                  )}
                  <Flex wrap={true} vertical>
                    <Title level={2}>{story.name}</Title>
                    <Flex gap='4px'>
                      <span>{story.country}</span>,
                      <span>{story.state}</span>,
                      <span>Batch - {story.batchNumber}</span>,
                      <span>Course - {story.course_name}</span>
                    </Flex>
                    <Space align="center" style={{marginTop: '1em'}}>
                        <Text strong>{story.rating}</Text>
                        <Rate allowHalf value={story.rating} disabled={true} style={{color: 'black'}}/>
                    </Space>
                  </Flex>
                </Flex>
              </Card>
            </StyledReview>
          </Flex>
          <DividerComponent />
        </Flex>
      ))}
    </StyledComponent>
  );
};

export default StoryComponent;
