import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import {
  Col,
  Divider,
  Row,
  Image,
  Typography,
  Flex,
  Card,
  List,
  Rate,
  Badge,
  Timeline,
} from "antd";
import styled from "@emotion/styled";
import DividerComponent from "./DividerComponent";

const { Title, Text } = Typography;

const StyledComponent = styled.div`
  .gutter-row {
    background: #fff;
    border: 1px solid #f0f0f0;
    padding: 20px;
    border-radius: 4px;
    .profile-basic-info {
      padding: 20px;
      .avatar {
        border-radius: 50%;
      }
      .ant-card {
        background: rgba(255, 150, 41, 0.06);
      }
      .ant-list-item {
        border: none;
        padding: 4px 0;
        span.title {
          background: #1489e6;
          padding: 8px;
          border-radius: 4px;
          color: #fff;
        }
      }
      .ant-rate {
        li {
          margin: 0;
          padding: 1px;
        }
      }
    }
  }

  .bio {
    padding: 0 40px 40px 40px;
    background: rgba(255, 150, 41, 0.06);
    .text-content {
      .ant-typography {
        line-height: 30px;
        text-align: justify;
      }
    }
  }

  .certificates {
    background: rgba(255, 150, 41, 0.06);
    .ant-list-item {
      width: 100%;
      svg {
        width: 32px;
        margin-right: auto;
        margin-left: 10px;
      }
    }
  }
  .timeline {
    background: rgba(255, 150, 41, 0.06);
    .ant-timeline {
      margin-top: 2em;
      .ant-timeline-item {
        padding-bottom: 10em;
        .ant-timeline-item-label {
          width: 40%;
          margin-top: 20px;
        }
        .ant-timeline-item-content {
          inset-block-start: 12px;
          margin-inline-start: 44px;
        }
        .ant-timeline-item-head {
          width: 48px;
          height: 48px;
          margin-left: -1.65em;
        }
        .ant-timeline-item-tail {
          border-inline-start: 2px dashed #666;
        }
      }
    }
  }
  .training {
    .ant-card-head {
      background: #1489e6;
      color: #fff;
    }
    .ant-card-grid {
      background: rgba(255, 150, 41, 0.06);
    }
  }

  .achievements {
    background: rgba(255, 150, 41, 0.06);
    .ant-flex {
      flex-wrap: wrap;
      .ant-image {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .ant-col {
      margin-bottom: 8px;
    }
  }

  @media screen and (min-width: 577px) {
    .column-1 {
      flex: 1;
      .gutter-row {
        height: 100%;
      }
    }
  }
`;

const info = {
  courses: [
    "Complete Python",
    "Python Fullstack",
    "Frontend Technologies",
    "Data Science",
  ],
  personal: [
    {
      label: "Age",
      value: 40,
    },
    {
      label: "Qualification",
      value: "Mtech",
    },
    {
      label: "IT Experience",
      value: "20+ yrs",
    },
    {
      label: "Training Experience",
      value: "10+ yrs",
    },
    {
      label: "Rating",
      value: <Rate allowHalf value={5} disabled={true} />,
    },
  ],
};

const certificates = [
  {
    title: "Btech",
    institute: "From Redacted",
    icon: "../education-hat.webp",
    url: "../certificate.jpg",
  },
  {
    title: "Mtech",
    institute: "From Redacted",
    icon: "../education-hat.webp",
    url: "../certificate.jpg",
  },
];

const trainings = [
  {
    url:'../student.png',
    count: 64,
    color: "#1489e6",
    label: 'Batches'
  },
  {
    url:'../study.png',
    count: 1245,
    color: "#36827f",
    label: 'Students'
  },
  {
    url:'../test.png',
    count: 10901,
    color: "#f9db6d",
    label: 'Weekly Tests'
  },
];

const rewards = [
  {
    url: "../certificate.jpg",
    icon: "../medal.png",
  },
  {
    url: "../certificate.jpg",
    icon: "../reward.png",
  },
  {
    url: "../certificate.jpg",
    icon: "../medal.png",
  },
  {
    url: "../certificate.jpg",
    icon: "../reward.png",
  },
  {
    url: "../certificate.jpg",
    icon: "../medal.png",
  },
  {
    url: "../certificate.jpg",
    icon: "../reward.png",
  },
  {
    url: "../certificate.jpg",
    icon: "../medal.png",
  },
];
const About = () => {
  const location = useLocation();
  const targetRef = useRef(null);

  useEffect(() => {
    // Get the href tag value from the URL
    const hrefValue = new URLSearchParams(location.search).get("href");
    
    if (hrefValue) {
      // Find the corresponding element based on the href value
      const targetElement = document.getElementById(hrefValue);
      if (targetElement) {
        targetRef.current = targetElement;
        // Scroll to the position of the target element
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <StyledComponent>
      <Row gutter={16} id="D.Redacted" ref={targetRef}>
        <Col xs={24} md={6} lg={7} className='column-1'>
          <div className='gutter-row'>
            <div className='profile-basic-info'>
              <Flex vertical align={"center"}>
                <Image
                  src='instructor.jpg'
                  preview={false}
                  style={{ maxWidth: "350px" }}
                  className='avatar'
                />
                <Title level={4} style={{ marginBottom: 0 }}>
                  Redacted
                </Title>
                <Text type='secondary' strong>
                  Python Instructor
                </Text>
              </Flex>
              <DividerComponent />
              <Card>
                <Flex vertical>
                  <List
                    itemLayout='horizontal'
                    dataSource={info.personal}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <Text strong>
                              {item.label} : {item.value}
                            </Text>
                          }
                        />
                      </List.Item>
                    )}
                  />
                  <List
                    itemLayout='horizontal'
                    dataSource={info.courses}
                    renderItem={(item, index) => (
                      <List.Item>
                        <span className='title'>{item}</span>
                      </List.Item>
                    )}
                  />
                </Flex>
              </Card>
            </div>
          </div>
        </Col>
        <Col xs={24} md={10} lg={10}>
          <Row gutter={[8, 16]}>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row bio'>
                <Title level={4}>Bio</Title>
                <Flex className='text-content' vertical>
                  <Text>
                    D. Redacted is a seasoned professional with a robust
                    educational background, holding an M.Tech degree from Redacted. With over 20 years of experience in the IT
                    industry, Redacted has amassed extensive knowledge and
                    skills in Python, Data Science technologies.
                  </Text>
                  <Text>
                    Notably, he trained 60+ batches so far successfully and
                    possess over a decade of experience in training, indicating
                    a strong capability for imparting knowledge and training
                    graduates, job aspirants. Having worked with multiple MNC
                    companies across different continents, Mr. Redacted likely
                    has a diverse range of experiences and perspectives in the
                    IT sector.
                  </Text>
                </Flex>
              </Flex>
            </Col>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row certificates'>
                <Title level={4}>Educational Certificates</Title>
                <Flex className='text-content' vertical>
                  <List
                    itemLayout='horizontal'
                    dataSource={certificates}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Flex justify='space-between' align='center'>
                          <Text strong>
                            {item.title} : {item.institute}
                          </Text>
                          {/* <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='currentColor'
                          class='w-6 h-6'
                        >
                          <path d='M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z' />
                          <path d='M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z' />
                          <path d='M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z' />
                        </svg> */}
                          <Image
                            width={50}
                            src={item.icon}
                            preview={{
                              src: item.url,
                            }}
                          />
                        </Flex>
                      </List.Item>
                    )}
                  />
                </Flex>
              </Flex>
            </Col>
            <Col md={24} xs={24}>
              <Flex vertical className='training'>
                <Flex className='text-content' vertical>
                  <Card
                    title='Training Experience'
                    style={{ background: "transparent" }}
                  >
                    {trainings.map((item) => (
                      <Card.Grid>
                        <Flex vertical align='center'>
                          <Image src={item.url} preview={false} width={50}/>
                          <Flex style={{marginTop: '10px'}} vertical align="center">
                            <Title level={3} style={{margin: 0}}>{item.count}</Title>
                            <Text strong>{item.label}</Text>
                          </Flex>
                        </Flex>
                      </Card.Grid>
                    ))}
                  </Card>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={8} lg={7}>
          <Row gutter={[8, 16]}>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row timeline'>
                <Title level={4} style={{ marginTop: "10px" }}>
                  IT Experience
                </Title>
                <Timeline
                  mode={"left"}
                  items={[
                    {
                      label: "2005 To 2006",
                      children: "TCE",
                    },
                    {
                      label: "2006 To 2010",
                      children: "HCL",
                    },
                    {
                      label: "2010 To 2024",
                      children: "Microsoft",
                    },
                  ]}
                />
              </Flex>
            </Col>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row achievements'>
                <Title level={4} style={{ marginTop: "10px" }}>
                  Awards & Rewards
                </Title>
                <Flex wrap gap={"medium"}>
                  <Image.PreviewGroup>
                    {rewards.map((item) => (
                      <Image
                        width={50}
                        src={item.icon}
                        preview={{
                          src: item.url,
                        }}
                      />
                    ))}
                  </Image.PreviewGroup>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
      <DividerComponent />
      <Row gutter={16} id="G.Saikumar" ref={targetRef}>
        <Col xs={24} md={6} lg={7} className='column-1'>
          <div className='gutter-row'>
            <div className='profile-basic-info'>
              <Flex vertical align={"center"}>
                <Image
                  src='instructor.jpg'
                  preview={false}
                  style={{ maxWidth: "350px" }}
                  className='avatar'
                />
                <Title level={4} style={{ marginBottom: 0 }}>
                  Sai Kumar
                </Title>
                <Text type='secondary' strong>
                  Python Instructor
                </Text>
              </Flex>
              <DividerComponent />
              <Card>
                <Flex vertical>
                  <List
                    itemLayout='horizontal'
                    dataSource={info.personal}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <Text strong>
                              {item.label} : {item.value}
                            </Text>
                          }
                        />
                      </List.Item>
                    )}
                  />
                  <List
                    itemLayout='horizontal'
                    dataSource={info.courses}
                    renderItem={(item, index) => (
                      <List.Item>
                        <span className='title'>{item}</span>
                      </List.Item>
                    )}
                  />
                </Flex>
              </Card>
            </div>
          </div>
        </Col>
        <Col xs={24} md={10} lg={10}>
          <Row gutter={[8, 16]}>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row bio'>
                <Title level={4}>Bio</Title>
                <Flex className='text-content' vertical>
                  <Text>
                    D. Redacted is a seasoned professional with a robust
                    educational background, holding an M.Tech degree from Redacted. With over 20 years of experience in the IT
                    industry, Redacted has amassed extensive knowledge and
                    skills in Python, Data Science technologies.
                  </Text>
                  <Text>
                    Notably, he trained 60+ batches so far successfully and
                    possess over a decade of experience in training, indicating
                    a strong capability for imparting knowledge and training
                    graduates, job aspirants. Having worked with multiple MNC
                    companies across different continents, Mr. Redacted likely
                    has a diverse range of experiences and perspectives in the
                    IT sector.
                  </Text>
                </Flex>
              </Flex>
            </Col>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row certificates'>
                <Title level={4}>Educational Certificates</Title>
                <Flex className='text-content' vertical>
                  <List
                    itemLayout='horizontal'
                    dataSource={certificates}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Flex justify='space-between' align='center'>
                          <Text strong>
                            {item.title} : {item.institute}
                          </Text>
                          {/* <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          fill='currentColor'
                          class='w-6 h-6'
                        >
                          <path d='M11.7 2.805a.75.75 0 0 1 .6 0A60.65 60.65 0 0 1 22.83 8.72a.75.75 0 0 1-.231 1.337 49.948 49.948 0 0 0-9.902 3.912l-.003.002c-.114.06-.227.119-.34.18a.75.75 0 0 1-.707 0A50.88 50.88 0 0 0 7.5 12.173v-.224c0-.131.067-.248.172-.311a54.615 54.615 0 0 1 4.653-2.52.75.75 0 0 0-.65-1.352 56.123 56.123 0 0 0-4.78 2.589 1.858 1.858 0 0 0-.859 1.228 49.803 49.803 0 0 0-4.634-1.527.75.75 0 0 1-.231-1.337A60.653 60.653 0 0 1 11.7 2.805Z' />
                          <path d='M13.06 15.473a48.45 48.45 0 0 1 7.666-3.282c.134 1.414.22 2.843.255 4.284a.75.75 0 0 1-.46.711 47.87 47.87 0 0 0-8.105 4.342.75.75 0 0 1-.832 0 47.87 47.87 0 0 0-8.104-4.342.75.75 0 0 1-.461-.71c.035-1.442.121-2.87.255-4.286.921.304 1.83.634 2.726.99v1.27a1.5 1.5 0 0 0-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.66a6.727 6.727 0 0 0 .551-1.607 1.5 1.5 0 0 0 .14-2.67v-.645a48.549 48.549 0 0 1 3.44 1.667 2.25 2.25 0 0 0 2.12 0Z' />
                          <path d='M4.462 19.462c.42-.419.753-.89 1-1.395.453.214.902.435 1.347.662a6.742 6.742 0 0 1-1.286 1.794.75.75 0 0 1-1.06-1.06Z' />
                        </svg> */}
                          <Image
                            width={50}
                            src={item.icon}
                            preview={{
                              src: item.url,
                            }}
                          />
                        </Flex>
                      </List.Item>
                    )}
                  />
                </Flex>
              </Flex>
            </Col>
            <Col md={24} xs={24}>
              <Flex vertical className='training'>
                <Flex className='text-content' vertical>
                  <Card
                    title='Training Experience'
                    style={{ background: 'transparent' }}
                  >
                    {trainings.map((item) => (
                      <Card.Grid>
                        <Flex vertical align='center'>
                          <Image src={item.url} preview={false} width={50}/>
                          <Flex style={{marginTop: '10px'}} vertical align="center">
                            <Title level={3} style={{margin: 0}}>{item.count}</Title>
                            <Text strong>{item.label}</Text>
                          </Flex>
                        </Flex>
                      </Card.Grid>
                    ))}
                  </Card>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={8} lg={7}>
          <Row gutter={[8, 16]}>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row timeline'>
                <Title level={4} style={{ marginTop: "10px" }}>
                  IT Experience
                </Title>
                <Timeline
                  mode={"left"}
                  items={[
                    {
                      label: "2005 To 2006",
                      children: "TCE",
                    },
                    {
                      label: "2006 To 2010",
                      children: "HCL",
                    },
                    {
                      label: "2010 To 2024",
                      children: "Microsoft",
                    },
                  ]}
                />
              </Flex>
            </Col>
            <Col md={24} xs={24}>
              <Flex vertical className='gutter-row achievements'>
                <Title level={4} style={{ marginTop: "10px" }}>
                  Awards & Rewards
                </Title>
                <Flex wrap gap={"medium"}>
                  <Image.PreviewGroup>
                    {rewards.map((item) => (
                      <Image
                        width={50}
                        src={item.icon}
                        preview={{
                          src: item.url,
                        }}
                      />
                    ))}
                  </Image.PreviewGroup>
                </Flex>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledComponent>
  );
};

export default About;
