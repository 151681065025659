import React, { useState, useEffect } from "react";
import { Layout, Menu, Drawer, Button, Image, Flex } from "antd";
import { Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { MenuOutlined } from "@ant-design/icons";
import { enquireScreen } from "enquire-js";

const StyledComponent = styled.div`
  .desktop {
    width: 80%;
    margin: 0 auto;
    .ant-menu-horizontal {
      border-bottom: 0;
      & > .ant-menu-item:after {
        bottom: 20px;
      }
    }
  }
  .mobile {
    margin: 0 34px;
    padding: 0;
  }
`;

const { Header } = Layout;

const HeaderComponent = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScreenChange = (b) => {
      setIsMobile(b ? true : false);
    };

    enquireScreen((b) => {
      handleScreenChange(b);
    });

    return () => {
      // Cleanup the enquire-js listener
      enquireScreen(null);
    };
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const items = [
    {
      key: 1,
      label: "Home",
      href: "/",
    },
    {
      key: 2,
      label: "About",
      href: "/about",
    },
    {
      key: 3,
      label: "Courses Offering",
      href: "/courses-offering",
    },
    {
      key: 4,
      label: "Course Calendar",
      href: "/course-calendar",
    },
    {
      key: 5,
      label: "Stories",
      href: "/stories",
    },
    {
      key: 6,
      label: "Contact",
      href: "/contact",
    },
    {
      key: 7,
      label: <Button type="primary">Register</Button>,
      href: null,
    },
  ];

  if (isMobile) {
    return (
      <StyledComponent>
        <Header className="mobile">
          <Flex justify='space-between' align='center'>
            <Link to={'/'}>
              <Image src='logo-1.png' width={160} preview={false} />
            </Link>
            <>
              <Button type='primary' onClick={showDrawer}>
                <MenuOutlined />
              </Button>
              <Drawer
                placement='right'
                closable={true}
                onClose={onClose}
                open={visible}
                bodyStyle={{ padding: 0 }}
                width={250}
                style={{
                  overflow: "auto",
                  height: "100vh",
                  position: "fixed",
                  right: 0,
                  width: "250px",
                  zIndex: 999,
                }}
              >
                <Menu mode='inline'>
                  {items.map((item) => (
                    <Menu.Item key={item.key}>
                      <Link to={item.href}>{item.label}</Link>
                    </Menu.Item>
                  ))}
                </Menu>
              </Drawer>
            </>
          </Flex>
        </Header>
      </StyledComponent>
    );
  }

  return (
    <StyledComponent>
      <Header className='desktop'>
        <Flex justify='space-between'>
          <Link to={'/'}>
            <Image src='logo-1.png' width={160} preview={false} />
          </Link>
          <Menu mode='horizontal'>
            {items.map((item) => (
              <Menu.Item key={item.key} className={location.pathname === item.href ? 'ant-menu-item-active' : ''}>
                <Link to={item.href}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
        </Flex>
      </Header>
    </StyledComponent>
  );
};

export default HeaderComponent;
