// src/App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ConfigProvider } from "antd";
import {
  Home,
  About,
  Contact,
  NotFound,
  CalendarComponent,
  CourseOfferingComponent,
  StoryComponent
} from "./components";
import Main from "./components/Main";

const theme = {
  token: {
    colorPrimary: "#1489e6",
    colorBgContainer: "#ff9629",
    fontFamily: "Poppins, sans-serif",
    buttonHoverColor: "#000",
    components: {
      Button: {
        borderRadius: 2,
        buttonHoverColor: "#ff9629",
        algorithm: true,
      },
      Card: {
        borderRadius: 8,
        algorithm: true,
      },
      Table: {
        background: "#fff",
      },
    },
  },
};

function App() {
  return (
    <ConfigProvider theme={theme}>
      <Main>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/course-calendar' element={<CalendarComponent />} />
          <Route path='/courses-offering' element={<CourseOfferingComponent />} />
          <Route path='/stories' element={<StoryComponent />} />
          {/* Fallback route */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Main>
    </ConfigProvider>
  );
}

export default App;
