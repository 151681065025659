import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Flex,
  Image,
  Typography,
  Badge,
  Button,
  Rate,
  Space,
  Modal,
} from "antd";
import DividerComponent from "./DividerComponent";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import TrainerAvatarComponent from "./TrainerAvatarComponent";
import {FieldTimeOutlined, ClockCircleOutlined, CalendarOutlined, FilePdfFilled} from '@ant-design/icons';

const { Title, Text } = Typography;

const courses = [
  {
    img: "../complete-python.jpg",
    name: "Complete Python",
    instructor: "Redacted",
    designation: "Redacted alumini, 20+ yrs exp",
    rating: 4.6,
    reviews: 78900,
    duration: "1.5 months",
    next_batch: "Next Batch - 23rd May 2024, Batch-65",
    class_schedule: [
      {
        day: "M",
        color: "red",
      },
      {
        day: "T",
        color: "green",
      },
      {
        day: "W",
        color: "yellow",
      },
      {
        day: "T",
        color: "pink",
      },
      {
        day: "F",
        color: "purple",
      },
      {
        day: "S",
        color: "black",
      },
    ],
    class_time: "7:30 am - 9:00 am IST",
    cost: 18000,
    student_rating: 4.7,
    total_ratings: 43221,
    trainer: {
      name: "D.Redacted",
      education: "Redacted Alumni",
      designation: "Senior Data Scientist",
      avatar: "../instructor.jpg",
    },
    schedule: "Coming Soon",
    rating: 0,
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam mollitia debitis repellendus 
      nostrum labore tempora saepe dicta amet? Ipsam sapiente dicta sequi
      `,
    courseUrl: "https://www.youtube.com/embed/AIma4XLjA08?si=NMAf9P_nMc-YlG8E",
    id:1,
  },
  {
    img: "../python-fullstack.jpg",
    name: "Python Fullstack",
    instructor: "Redacted",
    designation: "Redacted alumini, 20+ yrs exp",
    rating: 4.6,
    reviews: 78900,
    duration: "1.5 months",
    next_batch: "Next Batch - 23rd May 2024, Batch-65",
    class_schedule: [
      {
        day: "M",
        color: "red",
      },
      {
        day: "T",
        color: "green",
      },
      {
        day: "W",
        color: "yellow",
      },
      {
        day: "T",
        color: "pink",
      },
      {
        day: "F",
        color: "purple",
      },
      {
        day: "S",
        color: "black",
      },
    ],
    class_time: "7:30 am - 9:00 am IST",
    cost: 12000,
    student_rating: 4,
    total_ratings: 43221,
    trainer: {
      name: "D.Redacted",
      education: "Redacted Alumni",
      designation: "Senior Data Scientist",
      avatar: "../instructor.jpg",
    },
    schedule: "Coming Soon",
    rating: 0,
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam mollitia debitis repellendus 
      nostrum labore tempora saepe dicta amet? Ipsam sapiente dicta sequi`,
    courseUrl: "https://www.youtube.com/embed/AIma4XLjA08?si=NMAf9P_nMc-YlG8E",
    id:2
  },
  {
    img: "../frontend-technology.jpg",
    name: "Frontend Technologies",
    instructor: "Redacted",
    designation: "Redacted alumini, 20+ yrs exp",
    rating: 4.6,
    reviews: 78900,
    duration: "1.5 months",
    next_batch: "Next Batch - 23rd May 2024, Batch-65",
    class_schedule: [
      {
        day: "M",
        color: "red",
      },
      {
        day: "T",
        color: "green",
      },
      {
        day: "W",
        color: "yellow",
      },
      {
        day: "T",
        color: "pink",
      },
      {
        day: "F",
        color: "purple",
      },
      {
        day: "S",
        color: "black",
      },
    ],
    class_time: "7:30 am - 9:00 am IST",
    cost: 10000,
    student_rating: 4,
    total_ratings: 43221,
    trainer: {
      name: "D.Redacted",
      education: "Redacted Alumni",
      designation: "Senior Data Scientist",
      avatar: "../instructor.jpg",
    },
    schedule: "Coming Soon",
    rating: 0,
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam mollitia debitis repellendus 
      nostrum labore tempora saepe dicta amet? Ipsam sapiente dicta sequi`,
    courseUrl: "https://www.youtube.com/embed/AIma4XLjA08?si=NMAf9P_nMc-YlG8E",
    id:3
  },
  {
    img: "../data-science.jpg",
    name: "Python Fullstack with Data Science",
    instructor: "Redacted",
    designation: "Redacted alumini, 20+ yrs exp",
    rating: 4.6,
    reviews: 78900,
    duration: "1.5 months",
    next_batch: "Next Batch - 23rd May 2024, Batch-65",
    class_schedule: [
      {
        day: "M",
        color: "red",
      },
      {
        day: "T",
        color: "green",
      },
      {
        day: "W",
        color: "yellow",
      },
      {
        day: "T",
        color: "pink",
      },
      {
        day: "F",
        color: "purple",
      },
      {
        day: "S",
        color: "black",
      },
    ],
    class_time: "7:30 am - 9:00 am IST",
    cost: 24000,
    student_rating: 4,
    total_ratings: 43221,
    trainer: {
      name: "D.Redacted",
      education: "Redacted Alumni",
      designation: "Senior Data Scientist",
      avatar: "../instructor.jpg",
    },
    schedule: "Coming Soon",
    rating: 0,
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam mollitia debitis repellendus 
      nostrum labore tempora saepe dicta amet? Ipsam sapiente dicta sequi`,
    courseUrl: "https://www.youtube.com/embed/AIma4XLjA08?si=NMAf9P_nMc-YlG8E",
    id: 4
  },
];

const downloadPdf = (courseId) => {
  window.open("../test.pdf", "_blank");
};

const StyledComponent = styled.div`
  .ant-card {
    background: #fff;
    border:0;
    padding: 0;
    width: 100%;
    .ant-card-body {
      h3 {
        margin: 0;
      }
      .course-image {
        border-radius: 4px;
        // width: 40%;
      }
      .ant-ribbon-wrapper {
        .ant-card-body {
          padding: 24px;
          border:1px solid #ddd;
          border-top: none;
        }
        flex: 1;
        .ant-card-head {
            background: linear-gradient(45deg, #1489e6, #28a745);
            min-height: 54px;
            color: #fff;
        }
        .ant-ribbon {
            padding: 0 4em;
            line-height: 30px;
        }
      }
        .course-timings {
          margin-top: 1em;
          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .anticon {
              font-size: 1.5em;
            }
            .ant-space {
              margin-left: 10px;
            }
            & > span:last-child {
              margin-right: auto;
              margin-left: 10px;
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
            
          }
          .anticon-file-pdf {
              color: red;
              font-size: 2.2em
          }
          .ant-badge-count {
            color: #fff;
            box-shadow: none;
          }
          a {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
  .register-btn {
    align-self: flex-end;
    width: 180px;
    margin-top: 1em;
    background: #52c41a;
    &:hover {
      background: #52c41a !important;
    }
  }
`;

const CourseOfferingComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [course, setCourse] = useState(null);

  const showCourseDemo = (course) => {
    setIsModalOpen(true);
    setCourse(course);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <StyledComponent>
      {courses.map((item, index) => (
        <div>
          <Card>
            <Badge.Ribbon text={item.next_batch} color='gold'>
              <Card title={item.name} size='default' bordered={false}>
                <Flex justify='space-between' align='start'>
                  <Image
                    src={item.img}
                    preview={false}
                    className='course-image'
                    width={'25%'}
                  />
                  <Flex vertical style={{width:'72%'}}>
                    <Flex justify='space-between'>
                      <Text
                        style={{
                          lineHeight: "2em",
                          flex: 1,
                          marginRight: "10px",
                        }}
                      >
                        {item.description}
                      </Text>
                      <Space direction="vertical">
                          <Title
                            level={1}
                            type={"success"}
                            style={{ fontSize: "3em", margin: 0 }}
                          >
                            ₹{item.cost}/-
                          </Title>
                          <Button
                            type='primary'
                            size={"large"}
                            className='register-btn'
                          >
                          Register
                        </Button>
                      </Space>
                    </Flex>
                    <Flex
                      justify='space-between'
                      style={{
                        marginTop: "2em",
                        background: "#efefef",
                        padding: "2em",
                      }}
                    >
                      <Space direction='vertical'>
                        <Flex>
                          <TrainerAvatarComponent
                            imgSrc={item?.trainer?.avatar}
                            name={item?.trainer?.name}
                            education={item?.trainer?.education}
                            designation={item?.trainer?.designation}
                          />
                        </Flex>
                        <Flex style={{ marginTop: "1em" }}>
                          <Text strong style={{ marginRight: "10px" }}>
                            {item.student_rating}
                          </Text>
                          <Rate
                            allowHalf
                            value={item.student_rating}
                            disabled={true}
                          />
                          <Text>({item.total_ratings})</Text>
                        </Flex>
                        {/* <Flex>
                          <Link to={"/"}>
                            <Text strong>Schedule: {item.schedule}</Text>
                          </Link>
                        </Flex> */}
                      </Space>
                      <Flex vertical className='course-timings' wrap={true}>
                        <>
                          <div className="item">
                            <FieldTimeOutlined />
                            <span>{item.duration}</span>
                          </div>
                          <div className="item">
                            <CalendarOutlined />
                            <Space direction='vertical' size={"small"}>
                              <Flex wrap={true} gap='small'>
                                {item.class_schedule.map((item) => (
                                  <Badge count={item.day} color={item.color} />
                                ))}
                              </Flex>
                            </Space>
                          </div>
                          <div className="item">
                            <ClockCircleOutlined />
                            <span>{item.class_time}</span>
                          </div>
                        </>
                        <Space>
                          <Image
                            src='../youtube.png'
                            preview={false}
                            width={30}
                            onClick={() => showCourseDemo(item)}
                          />
                          <FilePdfFilled onClick={() => downloadPdf(item.id)} />
                        </Space>
                      </Flex>
                    </Flex>
                    
                  </Flex>
                </Flex>
              </Card>
            </Badge.Ribbon>
          </Card>
          <DividerComponent />
        </div>
      ))}
      <Modal
        width={1000}
        title={course?.name}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe
          width='950'
          height='400'
          src={course?.courseUrl}
          style={{ border: 0, borderRadius: "8px" }}
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerpolicy='strict-origin-when-cross-origin'
          allowfullscreen
        ></iframe>
      </Modal>
    </StyledComponent>
  );
};

export default CourseOfferingComponent;
