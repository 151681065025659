import { Divider } from "antd";
import { HolderOutlined } from "@ant-design/icons";

const DividerComponent = () => {
  return (
    <Divider style={{margin: '50px 0'}}>
      {Array.from({ length: 5 }).map((_, index) => (
        <HolderOutlined key={index} />
      ))}
    </Divider>
  );
};

export default DividerComponent;
