import React from "react";
import { Card, Rate, Typography, Flex, Space, Button } from "antd";

const { Meta } = Card;
const { Title, Text } = Typography;

const CardComponent = ({ item }) => {
  const { img, name, instructor, rating, designation, reviews } = item;
  return (
    <Card
      hoverable
      style={{
        width: 280,
      }}
      cover={<img alt={name} src={img} />}
    >
      <Flex vertical>
        <div>
          <Space direction="vertical">
            <Title level={5}>{name}</Title>
            <Text>{instructor}</Text>
            <Text>{designation}</Text>
          </Space>
          <Space>
            <Text>{rating}</Text>
            <Rate allowHalf value={rating} disabled={true} />
            <Text>({reviews})</Text>
          </Space>
          <Button style={{marginTop: '1em', marginLeft: 'auto'}}>More Details...</Button>
        </div>
      </Flex>
    </Card>
  );
};

export default CardComponent;
