import React from "react";
import { Flex, Avatar, Space, Typography } from "antd";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const TrainerAvatarComponent = (props) => {
  const { imgSrc, name, education, designation } = props;
  return (
    <Flex>
      <Avatar src={imgSrc} style={{width:'64px', height: '64px'}}/>
      <Space direction='vertical' size={"small"} style={{marginLeft: '10px'}}>
        <Link to={`/about?href=${name}`}>
          <Text underline>{name}</Text>
        </Link>
        <Text strong>{education}</Text>
        <Text>{designation}</Text>
      </Space>
    </Flex>
  );
};

export default TrainerAvatarComponent;
