import { Flex, Image, Menu, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import {
  LinkedinFilled,
  InstagramFilled,
  FacebookFilled,
  XOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  .footer-menu {
    background: transparent;
    width: 100%;
    justify-content: center;
    margin-top: 2em;
    & > .ant-menu-item:after {
      bottom: 10px;
    }
  }
  .social-icons {
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 1.5em;
    a {
        color: #545454;
    }
  }

  .copyright {
    margin-top: 1em;
    background: #545454;
    padding: 16px;
    span {
      color: #fff;
    }
  }
`;

const items = [
  {
    key: 1,
    label: "Home",
    href: "/",
  },
  {
    key: 2,
    label: "About",
    href: "/about",
  },
  {
    key: 3,
    label: "Courses Offering",
    href: "/courses-offering",
  },
  {
    key: 4,
    label: "Course Calendar",
    href: "/course-calendar",
  },
  {
    key: 5,
    label: "Stories",
    href: "/stories",
  },
  {
    key: 6,
    label: "Contact",
    href: "/contact",
  }
];

const Footer = () => {
  return (
    <StyledComponent>
      <Flex
        vertical
        align='center'
        justify='center'
        style={{ marginTop: "5em" }}
      >
        <Link to={"/"}>
          <Image src='logo-1.png' width={160} preview={false} />
        </Link>
        <Menu mode='horizontal' className='footer-menu'>
          {items.map((item) => (
            <Menu.Item key={item.key}>
              <Link to={item.href}>{item.label}</Link>
            </Menu.Item>
          ))}
        </Menu>
        <Space className='social-icons'>
          <Link to={'/'}>
            <LinkedinFilled />
          </Link>
          <Link to={'/'}>
            <XOutlined />
          </Link>
          <Link to={'/'}>
            <FacebookFilled />
          </Link>
          <Link to={'/'}>
            <InstagramFilled />
          </Link>
        </Space>
      </Flex>
      <Flex className='copyright' align='center' justify='center'>
        <Text>&copy; 2024 My Trainer. All Rights Reserved.</Text>
      </Flex>
    </StyledComponent>
  );
};

export default Footer;
