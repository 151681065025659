import React from "react";
import styled from "@emotion/styled";
import { Layout } from "antd";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import { useLocation } from "react-router-dom";

const { Content, Footer: AntdFooter } = Layout;

const StyledLayout = styled(Layout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fff;
  .ant-layout-header {
    background: #fff;
    padding: 0;
    &.desktop {
      .ant-menu-light {
        background: #fff;
        width: 62%;
        margin-left: auto;
      }
    }
  }
`;

const StyledContent = styled(Content)`
  flex: 1;
  width: 80%;
  margin: 3em auto;
  overflow-x: hidden;
`;

function Main({ children }) {
  const location = useLocation();
  const ContentLayout = () => (location.pathname === '/') ? children : <StyledContent>{children}</StyledContent>;
  
  return (
    <StyledLayout>
      <HeaderComponent />
      <ContentLayout />
      <AntdFooter style={{ padding: 0 }}>
        <FooterComponent />
      </AntdFooter>
    </StyledLayout>
  );
}

export default Main;
