import React, { useState } from "react";
import { Typography, Button, Flex, Avatar, Space, Badge } from "antd";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { TableComponent } from "./TableComponent";
import { FilePdfFilled } from "@ant-design/icons";
import DividerComponent from "./DividerComponent";
import TrainerAvatarComponent from "./TrainerAvatarComponent";

const { Title, Text } = Typography;

const StyledComponent = styled.div`
  .ant-table-wrapper {
    .ant-table,
    table {
      background: #fff;
      .ant-table-thead > tr > th {
        // background: #fff;
      }
    }
    .ant-table-tbody > tr.ant-table-placeholder {
      background: #fff;
    }
    td.ant-table-column-sort {
      background: #fff;
    }
  }
  .anticon-file-pdf {
    color: red;
  }
  .ant-badge-count {
    color: #fff;
    box-shadow: none;
  }
`;

const columns = [
  {
    title: "Starts On",
    dataIndex: "start_on",
    key: "start_on",
    defaultSortOrder: "descend",
    onFilter: (value, record) => record.start_on.startsWith(value),
    sorter: (a, b) => a.start_on.length - b.start_on.length,
  },
  {
    title: "Duration",
    dataIndex: "duration",
    key: "duration",
    onFilter: (value, record) => record.duration.startsWith(value),
    sorter: (a, b) => a.duration.length - b.duration.length,
  },
  {
    title: "Class Time",
    dataIndex: "class_time",
    key: "class_time",
    sorter: (a, b) => a.class_time.length - b.class_time.length,
    render: (text, record) => (
      <Space direction='vertical' size={"small"}>
        <Text strong>{record.class_time}</Text>
        <Text>({record.daily_class})</Text>
      </Space>
    ),
  },
  {
    title: "Class Days",
    dataIndex: "class_days",
    key: "class_days",
    sorter: (a, b) => a.class_days.length - b.class_days.length,
    render: (text, record) => (
      <Space direction='vertical' size={"small"}>
        <Flex wrap={true} gap='small'>
          {record.class_days.map((item) => (
            <Badge count={item.day} color={item.color} />
          ))}
        </Flex>
        <Text>({record.per_week})</Text>
      </Space>
    ),
  },
  {
    title: "Trainer",
    dataIndex: "trainer",
    key: "trainer",
    sorter: (a, b) => a.trainer.length - b.trainer.length,
    render: (text, record) => (
      <TrainerAvatarComponent
        imgSrc={record.trainer.avatar}
        name={record.trainer.name}
        education={record.trainer.education}
        designation={record.trainer.designation}
      />
    ),
  },
  {
    title: "Batch #",
    dataIndex: "batch",
    key: "batch",
    sorter: (a, b) => a.batch - b.batch,
  },
  {
    title: "Fee",
    dataIndex: "fee",
    key: "fee",
    sorter: (a, b) => a.fee - b.fee,
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (text, record) => <Button type='primary'>Register</Button>,
  },
];

const completePython = [
  {
    start_on: "May 20th 2024",
    duration: "2 Months",
    class_time: "7:30 am to 9:00 am IST",
    daily_class: "1.5hr class daily",
    class_days: [
      {
        day: "M",
        color: "red",
      },
      {
        day: "T",
        color: "green",
      },
      {
        day: "W",
        color: "yellow",
      },
      {
        day: "T",
        color: "pink",
      },
      {
        day: "F",
        color: "purple",
      },
      {
        day: "S",
        color: "black",
      },
    ],
    per_week: "6 days per week",
    trainer: {
      name: "D.Redacted",
      education: "Redacted Alumni",
      designation: "Senior Data Scientist",
      avatar: "../instructor.jpg",
    },
    batch: "Batch-65-cp",
    fee: "Rs. 10000/-",
  },
  {
    start_on: "Nov 20th 2024",
    duration: "2 Months",
    class_time: "7:30 am to 9:00 am IST",
    daily_class: "1.5hr class daily",
    class_days: [
      {
        day: "M",
        color: "red",
      },
      {
        day: "T",
        color: "green",
      },
      {
        day: "W",
        color: "yellow",
      },
      {
        day: "T",
        color: "pink",
      },
      {
        day: "F",
        color: "purple",
      },
      {
        day: "S",
        color: "black",
      },
    ],
    per_week: "6 days per week",
    trainer: {
      name: "Redacted",
      education: "Redacted Alumni",
      designation: "Senior Data Scientist",
      avatar: "../instructor.jpg",
    },
    batch: "Batch-65-cp",
    fee: "Rs. 10000/-",
  },
];

const downloadPdf = () => {
  window.open("../test.pdf", "_blank");
};

const CalendarComponent = () => {
  return (
    <StyledComponent>
      <div className='complete-python'>
        <Title level={4}>
          Complete Python: (Python Basics + Advanced Python + Regular
          Expressions) <FilePdfFilled onClick={downloadPdf} />
        </Title>
        <TableComponent columns={columns} data={completePython} />
      </div>
      <DividerComponent />
      <Title level={4}>
        Python Fullstack: (Complete Python + Database + Django + HTML + CSS+ BS
        + JS + React)
        <FilePdfFilled onClick={downloadPdf} />
      </Title>
      <TableComponent columns={columns} data={completePython} />
      <DividerComponent />
      <Title level={4}>
        Frontend Development: (HTML + CSS + BS + JS + React)
        <FilePdfFilled onClick={downloadPdf} />
      </Title>
      <TableComponent columns={columns} data={completePython} />
      <DividerComponent />
      <Title level={4}>
        Python Fullstack with Data Science: (Python Fullstack + Data Libararies
        + Mathematics + Machine Learning)
        <FilePdfFilled onClick={downloadPdf} />
      </Title>
      <TableComponent columns={columns} data={completePython} />
    </StyledComponent>
  );
};

export default CalendarComponent;
