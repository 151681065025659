import React, { useState, useRef } from "react";
import { Table, Button, Input, Space } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";

const paginationOptions = {
  showSizeChanger: true,
  pageSizeOptions: ["10", "20", "50", "100"],
};

const StyledFilterDropdown = styled.div`
  padding: 8px;
  .anticon-close {
    display: flex;
    margin-bottom: 8px;
    svg {
      margin-left: auto;
    }
  }
  .searchResetBtns {
    width: 100%;
    justify-content: space-between;
    .ant-space-item {
      width: 100%;
    }
  }
`;

export const TableComponent = (props) => {
  const { columns, data, rowKey='id' } = props;

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      bordered={true}
      onChange={onChange}
      showSorterTooltip={{
        target: "sorter-icon",
      }}
      pagination={false}
      rowKey={rowKey}
    />
  );
};
